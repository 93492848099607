import findIndex from "lodash-es/findIndex";

import UploadingFiles from "./common/uploading-files";

import { DocumentsService, InfoService } from "../../services";
import Vue from "vue";
import format from "date-fns/format";

function getParsedDebits(debits) {
  return (debits || []).map((debit) => {
    debit.checked = false;

    if (debit.status === "done") {
      debit.statusText = "Отработано";
    } else if (debit.status === "await") {
      debit.statusText = "Сдать до " + format(new Date(debit.submission_date), "dd.MM.yyyy");
    } else if (debit.status === "suspended") {
      debit.statusText = "Отложено до " + format(new Date(debit.suspension_date), "dd.MM.yyyy");
    }

    return debit;
  });
}

function resetState() {
  return {
    notifications_status: undefined,
    notifications_status_is_loading: false,
    notifications_status_is_updating: false,

    statements: [],
    statements_are_loading: false,
    clients: [],
    clients_are_loading: false,
    debits_are_loading: false,
    documents: [],

    extendedClients: {
      clients: [],
    },
    maxAllDebitsCount: 200, // максимальное количество всех списаний чтобы загрузить их одним запросом
    isAllDebitsLoading: false,
  };
}

export default {
  namespaced: true,

  state: resetState(),

  getters: {
    getNotificationsStatus({ notifications_status }) {
      return notifications_status;
    },

    isNotificationsStatusLoading({ notifications_status_is_loading }) {
      return notifications_status_is_loading;
    },

    isNotificationsStatusUpdating({ notifications_status_is_updating }) {
      return notifications_status_is_updating;
    },

    getStatements({ statements }) {
      return statements;
    },

    areStatementsLoading({ statements_are_loading }) {
      return statements_are_loading;
    },

    getClients({ clients }) {
      return clients;
    },

    areClientsLoading({ clients_are_loading }) {
      return clients_are_loading;
    },

    areDebitsLoading({ debits_are_loading }) {
      return debits_are_loading;
    },

    getDocumentsList(state) {
      return state.documents;
    },

    getExtendedClients(state) {
      return state.extendedClients;
    },

    getIsAllDebitsLoading(state) {
      return state.isAllDebitsLoading;
    },
  },

  mutations: {
    SET_NOTIFICATIONS_STATUS(state, value) {
      state.notifications_status = value;
    },

    SET_NOTIFICATIONS_STATUS_IS_LOADING(state, value) {
      state.notifications_status_is_loading = value;
    },

    SET_NOTIFICATIONS_STATUS_IS_UPDATING(state, value) {
      state.notifications_status_is_updating = value;
    },

    SET_STATEMENTS(state, statements) {
      state.statements = statements;
    },

    SET_IS_ALL_DEBITS_LOADING(state, payload) {
      state.isAllDebitsLoading = payload;
    },

    REPLACE_STATEMENT(state, statement) {
      const index = findIndex(state.statements, ["account.id", statement.account.id]);

      if (index == -1) return;

      state.statements.splice(index, 1, statement);
    },

    SET_STATEMENTS_ARE_LOADING(state, value) {
      state.statements_are_loading = value;
    },

    RESET_STATE(state) {
      Object.assign(state, resetState());
    },

    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },

    SET_CLIENTS_ARE_LOADING(state, value) {
      state.clients_are_loading = value;
    },

    SET_DEBITS_ARE_LOADING(state, value) {
      state.debits_are_loading = value;
    },

    SET_DOCUMENTS(state, value) {
      state.documents = value;
    },

    SET_EXTENDED_CLIENTS_FORMED(state, value) {
      const extendedClients = {
        overdue: 0,
        debits: 0,
        sum: 0,
        clients: JSON.parse(JSON.stringify(value || [])),
      };

      extendedClients.clients.forEach((client) => {
        extendedClients.overdue += client.overdue;
        extendedClients.sum += client.sum;
        client.checked = false;
        client.debits = 0; // HACK:
        client.postponed = 0; // HACK:
        client.contracts.forEach((contract) => {
          client.debits += contract.debits; // HACK: у клиента почему-то с бэка не приходит количество у него debits
          client.postponed += contract.postponed; // HACK: у клиента почему-то с бэка не приходит количество у него отложенных
          extendedClients.debits += contract.debits;
          contract.name = contract.presentation;
          contract.checked = false;
          contract.isDebitsLoading = false;
          contract.documents = [];
        });
      });

      state.extendedClients = extendedClients;
    },

    SET_EXTENDED_CLIENTS(state, value) {
      state.extendedClients = value;
    },

    SET_ALL_SELECTION_EXTENDED_CLIENTS(state) {
      state.extendedClients.clients.forEach((client) => {
        client.checked = true;

        client.contracts.forEach((contract) => {
          contract.checked = false;

          contract.documents.forEach((document) => {
            document.checked = false;
          });
        });
      });
    },

    CLEAR_SELECTION_EXTENDED_CLIENTS(state) {
      state.extendedClients.clients.forEach((client) => {
        client.checked = false;

        client.contracts.forEach((contract) => {
          contract.checked = false;

          contract.documents.forEach((document) => {
            document.checked = false;
          });
        });
      });
    },

    SET_PARSED_DEBITS(state, payload) {
      if (payload.contract) {
        // contract.documents = contract.documents.concat(getParsedDebits(contractDebits));
      } else {
        state.extendedClients.clients.forEach((client) => {
          client.contracts.forEach((contract) => {
            contract.documents = getParsedDebits(payload.debitsHash[contract.id]);
          });
        });
      }
    },

    UPDATE_EXTENDED_CLIENTS(state, payload) {
      const clientId = payload[0].client.id;
      const contractId = payload[0].contract.id;

      state.extendedClients.clients.forEach((client) => {
        if (client.id === clientId) {
          client.contracts.forEach((contract) => {
            if (contract.id === contractId) {
              payload.forEach((document) => {
                const indexDoc = contract.documents.findIndex((doc) => doc.id === document.id);
                if (indexDoc >= 0) {
                  contract.documents[indexDoc] = {
                    ...getParsedDebits([document])[0],
                  };
                }
              });
            }
          });
        }
      });
    },
  },

  actions: {
    async loadNotificationsStatus({ commit, rootGetters }, orgId) {
      commit("SET_NOTIFICATIONS_STATUS_IS_LOADING", true);

      try {
        const status = await DocumentsService.fetchNotificationsSuspension(orgId);

        if (rootGetters["Organizations/ActiveOne/getId"] != orgId) return;

        commit("SET_NOTIFICATIONS_STATUS", status);
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] == orgId) {
          commit("SET_NOTIFICATIONS_STATUS_IS_LOADING", false);
        }
      }
    },

    async loadStatements({ commit, rootGetters }, orgId) {
      commit("SET_STATEMENTS_ARE_LOADING", true);

      try {
        const statements = await DocumentsService.fetchStatements(orgId);

        if (rootGetters["Organizations/ActiveOne/getId"] != orgId) return;

        commit("SET_STATEMENTS", statements);
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] == orgId) {
          commit("SET_STATEMENTS_ARE_LOADING", false);
        }
      }
    },

    async updateNotificationsStatus({ commit }, { orgId, update }) {
      commit("SET_NOTIFICATIONS_STATUS_IS_UPDATING", true);

      try {
        const status = await DocumentsService.updateNotificationsSuspension({
          orgId,
          update,
        });

        commit("SET_NOTIFICATIONS_STATUS", status);
      } finally {
        commit("SET_NOTIFICATIONS_STATUS_IS_UPDATING", false);
      }
    },

    async uploadStatementFiles({ commit }, { bankAccountId, statementFilesIds }) {
      const statement = await DocumentsService.addStatementFiles({
        bankAccountId,
        statementFilesIds,
      });

      commit("REPLACE_STATEMENT", statement);
    },

    async updateNoCashPeriod({ commit }, { bankAccountId, period }) {
      const statement = await DocumentsService.updateNoCashPeriod({
        bankAccountId,
        period,
      });

      commit("REPLACE_STATEMENT", statement);
    },

    async loadClients({ commit, rootGetters }, orgId) {
      commit("SET_CLIENTS_ARE_LOADING", true);

      try {
        let clients = [];

        if (orgId) {
          clients = await DocumentsService.fetchClients(orgId);
        }
        if (rootGetters["Organizations/ActiveOne/getId"] !== orgId) return;

        commit("SET_CLIENTS", clients);

        return clients;
      } finally {
        if (rootGetters["Organizations/ActiveOne/getId"] === orgId) {
          commit("SET_CLIENTS_ARE_LOADING", false);
        }
      }
    },

    async fetchDebitsBatch(ctx, options) {
      const debits = await DocumentsService.fetchDebitsBatch(options);

      return debits;
    },

    async fetchDebitsByContract({ commit }, options) {
      commit("SET_DEBITS_ARE_LOADING", true);

      try {
        const debits = await DocumentsService.fetchDebitsByContract(options);

        return debits;
      } finally {
        commit("SET_DEBITS_ARE_LOADING", false);
      }
    },

    async fetchDebit(ctx, debitId) {
      const debit = await DocumentsService.fetchDebit(debitId);

      return debit;
    },

    async debitsSuspension({ commit }, options) {
      const result = await DocumentsService.debitsSuspension(options);

      commit("UPDATE_EXTENDED_CLIENTS", result);

      return result;
    },

    async uploadClosingFiles(ctx, options) {
      const upload = await DocumentsService.addClosingFiles(options);

      ctx.commit("UPDATE_EXTENDED_CLIENTS", upload);

      return upload;
    },

    async fetchDocumentsList({ commit }, orgId) {
      try {
        const response = await InfoService.fetchDocuments(orgId);

        commit("SET_DOCUMENTS", response);
      } catch (e) {
        Vue.notify({
          type: "error",
          text: "Ошибка при получении списка документов",
        });
      }
    },

    async updateExtendedClients({ state, dispatch }, orgId) {
      await dispatch("getExtendedClients", { orgId }); // сначала загружаем клиентов и их договора (+ добавляем инфу) и отображаем "верхние" уровни (без списаний)
      if (state.extendedClients.debits <= state.maxAllDebitsCount) {
        // если всего списаний не так много - то грузим их все сразу
        dispatch("getExtendedClientsWithDebits"); // загружаем все списания у всех договоров и отображаем их
      }
    },

    async getExtendedClients({ commit, dispatch }, data) {
      try {
        // this.isErrorClientsLoading = false;
        const clients = await dispatch("loadClients", data.orgId);

        commit("SET_EXTENDED_CLIENTS_FORMED", clients);
      } catch (error) {
        // this.isErrorClientsLoading = true;

        Vue.notify({
          type: "error",
          title: "Произошла ошибка при загрузке данных.",
          text: error,
          duration: -1,
        });

        commit("SET_EXTENDED_CLIENTS", {
          overdue: 0,
          debits: 0,
          sum: 0,
          clients: [],
        });
      }
    },

    async getExtendedClientsWithDebits({ dispatch, state, commit }, contract) {
      const clients = state.extendedClients;
      try {
        if (contract) {
          //используется ли этот фукционал?
          // если хотим получить списания конкретного договора
          // contract.isDebitsLoading = true;

          const lastDebit = contract.documents[contract.documents.length - 1];
          const lastId = (lastDebit && lastDebit.id) || "";
          const contractDebits = await dispatch("fetchDebitsByContract", {
            contractIds: contract.id,
            lastId: lastId,
          });

          commit("SET_PARSED_DEBITS", { contract, contractDebits });
          contract.documents = contract.documents.concat(getParsedDebits(contractDebits));

          // contract.isDebitsLoading = false;
        } else {
          // если хотим получить списания всех договоров
          commit("SET_IS_ALL_DEBITS_LOADING", true);

          const contractIds = clients.clients.reduce(
            (arr, client) => arr.concat(client.contracts.map((contract) => contract.id)),
            []
          );
          const allDebits = await dispatch("fetchDebitsByContract", { contractIds });

          const debitsHash = allDebits.reduce((obj, contract) => {
            obj[contract.contract_id] = contract.debits;
            return obj;
          }, {});

          commit("SET_PARSED_DEBITS", { debitsHash });

          commit("SET_IS_ALL_DEBITS_LOADING", false);
        }

        commit("SET_EXTENDED_CLIENTS", clients);
      } catch (error) {
        if (contract) {
          // contract.isDebitsLoading = false;
        } else {
          commit("SET_IS_ALL_DEBITS_LOADING", false);
        }

        Vue.notify({
          type: "error",
          title: "Произошла ошибка при загрузке данных.",
          text: error,
          duration: -1,
        });

        commit("SET_EXTENDED_CLIENTS", clients);
      }
    },

    setAllSelection({ commit }) {
      commit("SET_ALL_SELECTION_EXTENDED_CLIENTS");
    },

    clearSelection({ commit }) {
      commit("CLEAR_SELECTION_EXTENDED_CLIENTS");
    },

    resetState({ commit, dispatch }) {
      dispatch("UploadingFiles/resetState");

      commit("RESET_STATE");
    },
  },

  modules: {
    UploadingFiles: UploadingFiles(),
  },
};
