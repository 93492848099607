var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tariff-contract" }, [
    _vm.hasContract
      ? _c("div", { staticClass: "tariff-contract-wrapper" }, [
          _c(
            "div",
            { staticClass: "tariff-contract-aside" },
            [
              _c(
                "div",
                {
                  staticClass: "tariff-contract-documents-all",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.downloadFile.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.fullPackDocLoading
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/icons/documents/download.svg"),
                          alt: "download.svg",
                        },
                      })
                    : _c("img", {
                        staticClass: "tariff-contract-documents-all__loader",
                        attrs: {
                          src: require("@icons/bubble-loader.svg"),
                          width: "20",
                        },
                      }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Скачать комплект документов")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tariff-contract-documents scroll scroll--rounded",
                  class: {
                    "tariff-contract-documents--disabled": _vm.documentLoading,
                  },
                },
                [
                  _vm._l(_vm.documents, function (document) {
                    return _c(
                      "div",
                      {
                        key: document.guid,
                        staticClass: "tariff-contract-document",
                        on: {
                          click: function ($event) {
                            return _vm.showDocument(
                              document.doc_id,
                              document.form_id,
                              document.contract_id,
                              document.guid
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tariff-contract-document__body",
                            class: {
                              "tariff-contract-document--active":
                                document.guid === _vm.activeDocumentId,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tariff-contract-document__body__title",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(document?.name) +
                                    "\n              "
                                ),
                                document?.subtitle
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tariff-contract-document__body__subtitle",
                                      },
                                      [_vm._v(_vm._s(document?.subtitle))]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c("FileDownloadIcon", {
                              staticClass: "tariff-contract-document-icon",
                              attrs: {
                                "contract-id": document.contract_id,
                                "form-id": document.form_id,
                                "doc-id": document.doc_id,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(document.next_doc, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.guid,
                              staticClass: "tariff-contract-document__details",
                              class: {
                                "tariff-contract-document--active":
                                  item.guid === _vm.activeDocumentId,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showDocument(
                                    item.doc_id,
                                    item.form_id,
                                    item.contract_id,
                                    item.guid
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "tariff-contract-document__details__notify",
                                  class: { visible: !item.actualy },
                                },
                                [_vm._v("НОВ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "tariff-contract-document__details__title",
                                },
                                [
                                  _vm._v(
                                    "\n              Ред. № " +
                                      _vm._s(item.version) +
                                      ", вступ. в силу с " +
                                      _vm._s(
                                        _vm.formatDate(item.date, "dd.MM.yy")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("FileDownloadIcon", {
                                staticClass: "tariff-contract-document-icon",
                                attrs: {
                                  "contract-id": item.contract_id,
                                  "form-id": item.form_id,
                                  "doc-id": item.doc_id,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm.isDocumentsLoading
                    ? _c("img", {
                        staticClass: "contract-history__loader-icon",
                        attrs: {
                          src: require("@icons/bubble-loader.svg"),
                          width: "40",
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.contractStatus === "concluding"
                ? _c("MarginConfirmationBlock", {
                    staticClass: "tariff-contract-aside__confirmation",
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tariff-contract-main scroll" }, [
            _c("div", { staticClass: "tariff-contract-info" }, [
              _c(
                "div",
                { staticClass: "tariff-contract-status" },
                [
                  _c("AppIcon", {
                    staticClass: "tariff-contract-status-icon",
                    attrs: { src: _vm.statuses[_vm.contractStatus].icon },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tariff-contract-status-right" }, [
                    _c(
                      "div",
                      { staticClass: "tariff-contract-status-right__column" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tariff-contract-status-text",
                            style: {
                              color: _vm.statuses[_vm.contractStatus].color,
                              "background-color":
                                _vm.statuses[_vm.contractStatus].background,
                            },
                          },
                          [
                            _vm.contractStatus === "suspended" ||
                            _vm.contractStatus === "terminated"
                              ? _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.statuses[_vm.contractStatus].title
                                      ) +
                                      "\n                  "
                                  ),
                                  _vm.contractStatusDate
                                    ? _c("span", [
                                        _vm._v(
                                          " с " +
                                            _vm._s(
                                              _vm._f("localDate")(
                                                _vm.contractStatusDate
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.statuses[_vm.contractStatus].title
                                    )
                                  ),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "relative" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tariff-contract-history-link",
                                on: { click: _vm.openContractHistory },
                              },
                              [_vm._v("История действий")]
                            ),
                            _vm._v(" "),
                            _c("FadeTransaction", [
                              Object.keys(_vm.isOpenContractHistory).length
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "history-box",
                                      style: _vm.isOpenContractHistory,
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex justify-between" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "history-title" },
                                            [_vm._v("История действий")]
                                          ),
                                          _vm._v(" "),
                                          _c("AppIcon", {
                                            staticClass: "history-icon",
                                            attrs: { src: "raw-svg/cross.svg" },
                                            nativeOn: {
                                              click: function ($event) {
                                                _vm.isOpenContractHistory = {}
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("ContractHistory", {
                                        staticClass: "history-list scroll",
                                        style: {
                                          "max-height":
                                            _vm.isOpenContractHistory
                                              .heightContent,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.contractStatus !== "concluding"
                      ? _c(
                          "div",
                          { staticClass: "tariff-contract-status-dates" },
                          [
                            _c("div", { staticClass: "column-status-date" }, [
                              _c(
                                "div",
                                { staticClass: "column-status-date__title" },
                                [_vm._v("Дата договора")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "tariff-contract-status-date" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("localDate")(
                                          _vm.contractStartDate
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.contractServiceDate
                              ? _c(
                                  "div",
                                  { staticClass: "column-status-date" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "column-status-date__title",
                                      },
                                      [_vm._v("Начало оказания услуг")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tariff-contract-status-date",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("localDate")(
                                                _vm.contractServiceDate
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tariff-contract-history-block" }, [
                _c(
                  "div",
                  { staticClass: "tariff-contract-history-block-col" },
                  [
                    _c("div", {}, [_vm._v("Тариф")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tariff-contract-history-block-black" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.contractTariffName) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tariff-contract-history-block-col" },
                  [
                    _c("div", {}, [_vm._v("СНО")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tariff-contract-history-block-black" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.taxation?.name) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tariff-contract-history-block-col" },
                  [
                    _c("div", {}, [_vm._v("Вид бизнеса")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tariff-contract-history-block-black" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.businessForm?.name) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tariff-contract-doc" }, [
              _c("div", {
                ref: "docText",
                staticClass: "tariff-contract-text",
              }),
              _vm._v(" "),
              _vm.firstPageLoading
                ? _c("img", {
                    attrs: {
                      src: require("@icons/bubble-loader.svg"),
                      width: "40",
                    },
                  })
                : _vm._e(),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasContract
      ? _c("div", { staticClass: "tariff-contract-none" }, [
          _c(
            "div",
            { staticClass: "tariff-contract-none__icon-wr" },
            [
              _c("AppIcon", {
                staticClass: "tariff-contract-none__icon",
                attrs: { name: "file-clock" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tariff-contract-none__title" }, [
            _vm._v("Договор не заключен"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tariff-contract-none__description" }, [
            _vm._v("Для заключения договора обратитесь к аутсорсеру"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "tariff-contract-document all",
        attrs: {
          href: "https://1cbo.ru/rates_and_services/offer2/",
          target: "_blank",
        },
      },
      [
        _c("span", [_vm._v("Правила обслуживания")]),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: require("@icons/external-link.svg"),
            alt: "external-link.svg",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }