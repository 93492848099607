<template>
  <div class="confirmation">
    <div class="confirmation__wrapper-for-fourth-step">
      <div :class="classesForConfirmationBody">
        <template v-if="step == 'one'">
          <div :class="classesForText">
            {{ options[contractStatus].text }}
            <span>{{ earnestPayment | spaced }} р.</span>
          </div>

          <a download :href="invoiceLink" target="_blank" class="download-button" @click="goToStep('two')">
            <div class="download-button__icon" v-html="icons['download']"></div>
            <div class="download-button__text">Скачать счет</div>
          </a>
        </template>

        <template v-else-if="step == 'two'">
          <div class="confirmation__icon" v-html="icons['uploadFile']"></div>

          <div class="confirmation__text-info-wrapper">
            <div :class="classesForText">
              {{ options[contractStatus].confirmation }}
            </div>
            <div class="confirmation__info" :class="{ 'confirmation__info--error': !inputIsValid }">
              1 файл формата jpeg, png, tiff, pdf и не более 10Мб
            </div>
          </div>

          <label class="button" :for="inputId">
            <div class="button__icon" v-html="icons['paperclip']"></div>
            <div class="button__text">Прикрепить файл</div>
            <input
              :id="inputId"
              ref="hidden-input"
              class="confirmation__hidden-input"
              type="file"
              @change="handleInput"
            />
            <!-- @change="handleInput($event)" -->
          </label>
        </template>

        <template v-else-if="step == 'three'">
          <div class="confirmation__file file">
            <div class="file__name">
              <template v-if="!fileIsUploading">{{ fileName }}</template>
              <img v-else src="@icons/loading.svg" width="24" />
            </div>
            <img
              v-if="!fileIsUploading"
              class="file__dismiss"
              src="../../assets/icons/dismiss.svg"
              alt="dismiss.svg"
              @click="dismissFile"
            />
          </div>

          <a class="button" @click="uploadFile">
            <div class="button__icon" v-html="icons['sendFile']"></div>
            <div class="button__text">Отправить</div>
          </a>
        </template>

        <template v-else-if="step == 'four'">
          <div class="confirmation__icon" v-html="icons['check']"></div>
          <div :class="classesForText">Спасибо, платёжка отправлена</div>
        </template>
      </div>

      <div v-if="step == 'one' && contractStatus !== 'concluding'" class="footer-button" @click="goToStep('two')">
        <div class="footer-button__icon" v-html="icons['paperclip']"></div>
        <div>Загрузить поручение</div>
      </div>

      <a
        v-else-if="step == 'two' || step == 'three'"
        download
        :href="invoiceLink"
        target="_blank"
        class="footer-button"
      >
        <div class="footer-button__icon" v-html="icons['download']"></div>
        <div>Скачать счет повторно</div>
      </a>

      <div v-else-if="step == 'four' && contractStatus !== 'concluding'" class="footer-button" @click="goToStep('two')">
        <div class="footer-button__icon" v-html="icons['paperclip']"></div>
        <div>Загрузить поручение повторно</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { nanoid } from "nanoid";

import { API_URL, MARGIN_CONFIRMATION_BLOCK } from "../../utils/constants";

import downloadIcon from "../../assets/icons/raw-svg/margin-confirmation/download.svg";
import paperclipIcon from "../../assets/icons/raw-svg/margin-confirmation/paperclip.svg";
import sendFileIcon from "../../assets/icons/raw-svg/margin-confirmation/send-file.svg";
import checkIcon from "../../assets/icons/raw-svg/margin-confirmation/check.svg";
import uploadFileIcon from "../../assets/icons/raw-svg/margin-confirmation/upload-file.svg";

export default {
  name: "MarginConfirmationBlock",

  data() {
    return {
      icons: {
        download: downloadIcon,
        paperclip: paperclipIcon,
        sendFile: sendFileIcon,
        check: checkIcon,
        uploadFile: uploadFileIcon,
      },
      options: MARGIN_CONFIRMATION_BLOCK,

      inputId: nanoid(),
    };
  },

  computed: {
    ...mapGetters("Organizations/ActiveOne/MarginConfirmation", {
      step: "getCurrentStep",
      fileName: "getFileName",
      file: "getFile",
      fileIsUploading: "isFileUploading",
      inputIsValid: "isInputValid",
    }),

    ...mapGetters("Organizations/ActiveOne", {
      orgId: "getId",
      contractStatus: "contractStatus",
      earnestPayment: "getEarnestPayment",
      paymentOrderIsUploaded: "isPaymentOrderUploaded",
    }),

    classesForConfirmationBody() {
      return {
        confirmation__body: true,
        [`confirmation__body--step--${this.step}`]: true,
      };
    },

    classesForText() {
      return {
        confirmation__text: true,
        [`confirmation__text--step--${this.step}`]: true,
      };
    },

    invoiceLink() {
      return `${API_URL}/organization/${this.orgId}/offer/invoice`;
    },
  },

  mounted() {
    if (this.paymentOrderIsUploaded) this.goToStep("four");

    const dropArea = this.$el;

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    function preventDefaults(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    dropArea.addEventListener("drop", this.handleDrop, false);
  },

  methods: {
    ...mapActions("Organizations/ActiveOne/MarginConfirmation", [
      "goToStep",
      "validateFile",
      "uploadFile",
      "prepareFileForUpload",
      "resetFile",
    ]),

    async handleDrop(event) {
      if (this.step !== "two") return;

      const dt = event.dataTransfer;
      const file = dt.files[0];

      const isValid = await this.validateFile(file);
      if (!isValid) return;

      this.prepareFileForUpload(file);

      this.goToStep("three");
    },

    async handleInput(event) {
      const file = event.target.files[0];

      this.$refs["hidden-input"].value = "";

      const isValid = await this.validateFile(file);
      if (!isValid) return;

      this.prepareFileForUpload(file);

      this.goToStep("three");
    },

    dismissFile() {
      this.goToStep("two");
      this.resetFile();
    },
  },
};
</script>

<style scoped lang="scss">
/* stylelint-disable selector-class-pattern */

@mixin button($size: "small", $theme: "light") {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  &__icon {
    display: flex;
  }

  @if $size == "small" {
    height: 34px;
    font-size: 13px;

    &__icon {
      width: 13px;
      margin-right: 7px;
    }
  }

  @if $size == "big" {
    height: 42px;
    font-size: 16px;

    &__icon {
      width: 16px;
      margin-right: 10px;
    }
  }

  @if $theme == "light" {
    border: 1px solid #ececec;
    background: white;
    color: #6b6b6b;
  }

  @if $theme == "dark" {
    background: #fddf4d;
    color: #414141;
    font-weight: 700;
  }
}

$yellow: #fddf4d;

.confirmation {
  &__body {
    position: relative;
    padding: 18px;
    border-radius: 10px;
    margin-bottom: 10px;

    .offer-head & {
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 0;
      margin-left: 100px;
    }

    &--step {
      &--one {
        padding: 15px;
        background: $yellow;

        .offer-head & {
          background: white;
        }
      }

      &--two {
        border: 2px dashed $yellow;
        background: #fffefb;
        text-align: center;

        .offer-head & {
          border: none;
          text-align: left;
        }
      }

      &--three {
        border: 2px dashed $yellow;
        background: #fffcf0;

        .offer-head & {
          border: none;
        }
      }

      &--four {
        border: 2px dashed #e0e0e0;
        background: white;

        .offer-head & {
          border: none;
        }
      }
    }
  }

  &__icon {
    display: block;
    width: 80px;
    margin: 0 auto 8px;

    .offer-head & {
      display: none;
    }

    .offer-head .confirmation__body--step--four & {
      display: block;
      width: 44px;
      margin-bottom: 0;
    }
  }

  &__text {
    color: #414141;

    .offer-head & {
      width: 394px;
      font-size: 14px;
      font-weight: 400;

      span {
        color: #414141;
        font-weight: 700;
      }
    }

    &--step {
      &--one {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 700;

        .offer-head & {
          margin-right: 60px;
          margin-bottom: 0;
          color: #9c9c9c;
        }
      }

      &--two {
        margin-bottom: 9px;
        font-size: 14px;

        .offer-head & {
          margin-bottom: 4px;
          font-size: 13px;
        }
      }

      &--four {
        font-size: 14px;
        text-align: center;

        .offer-head & {
          width: unset;
          padding: 0 20px;
        }
      }
    }
  }

  &__info {
    margin-bottom: 10px;
    color: #848484;
    font-size: 12px;

    .offer-head & {
      margin-bottom: 0;
      font-size: 10px;
    }

    @keyframes blink {
      from {
        color: white;
      }

      to {
        color: red;
      }
    }

    &--error {
      animation: blink 0.3s steps(5, start) infinite;
    }
  }

  &__hidden-input {
    display: none;
  }

  &__file {
    width: 100%;
    height: 36px;
    margin-bottom: 6px;

    .offer-head & {
      width: 240px;
      height: 42px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
  }

  .offer-head &__wrapper-for-fourth-step {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.button {
  @include button("small", "dark");

  .offer-head & {
    @include button("big", "dark");
  }
}

.download-button {
  @include button("big", "light");

  height: 36px;
  color: #414141;
  font-weight: 700;

  .offer-head & {
    @include button("big", "dark");
  }
}

.footer-button {
  @include button("small");

  border: 1px solid #ececec;
  background: white;
  color: #6b6b6b;

  .offer-head & {
    display: none;
  }

  .offer-head .confirmation__body--step--four ~ & {
    display: flex;
  }
}

.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  &__name {
    overflow: hidden;
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__dismiss {
    margin-right: 12px;
    cursor: pointer;
  }
}
</style>
