<template>
  <div class="tariff-outsourcer">
    <div class="tariff-outsourcer__title">
      {{ organization.outsourcer }}
      <div class="outsourcer-details">
        <div v-for="(item, name, index) in infoOrg" :key="name + index" class="outsourcer-details__block">
          <p class="outsourcer-details__block__header">{{ item }}</p>
          <p v-if="name !== 'email'" class="outsourcer-details__block__body">{{ organization.adinfo[name] }}</p>
          <a v-else class="outsourcer-details__block__body email" :href="`mailto:${organization.adinfo[name]}`">{{
            organization.adinfo[name]
          }}</a>
        </div>
      </div>
    </div>
    <div class="tariff-outsourcer__members">
      <div v-if="isLoading" class="contract-history__loader-overlay">
        <img class="contract-history__loader-icon" src="@icons/bubble-loader.svg" width="40" />
      </div>

      <template v-else>
        <div
          v-for="responsible in responsibles"
          :key="responsible.user_id"
          class="tariff-outsourcer__member outsourcer-member"
        >
          <div class="outsourcer-member__avatar">
            <img
              v-if="responsible.avatar"
              :src="responsible.avatar.thumb.url"
              class="outsourcer-member__photo"
              :alt="responsible.avatar.name"
            />
            <div v-else class="outsourcer-member__initials">
              {{ responsible.name | nameInitials }}
            </div>
          </div>
          <div class="outsourcer-member__name">{{ responsible.name }}</div>
          <div class="outsourcer-member__role">{{ responsible.type }}</div>
          <a :href="`mailto:${responsible.email}`" class="outsourcer-member__email">
            {{ responsible.email }}
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue, { computed } from "vue";

import { useQuery } from "@tanstack/vue-query";

import { mapGetters } from "vuex";
import store from "../../store";

import { InfoService } from "../../services";

export default {
  name: "TariffOutsourcer",

  setup() {
    const infoOrg = { address: "Адрес", city: "Город", region: "Регион", phone: "Телефон", email: "Электронная почта" };
    const orgId = computed(() => store.getters["Organizations/ActiveOne/getId"]);

    const { data: responsibles, isLoading } = useQuery(
      ["responsibles", orgId],
      () => InfoService.fetchResponsibles(orgId.value),
      {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false,
        onError: () => {
          Vue.notify({
            type: "error",
            text: "Ошибка при получении списка ответственных",
          });
        },
      }
    );

    return { responsibles, isLoading, infoOrg };
  },

  computed: {
    ...mapGetters({
      organization: "Organizations/ActiveOne/organization",
    }),
  },
};
</script>

<style scoped lang="scss">
.tariff-outsourcer {
  padding: 24px;

  &__title {
    margin-bottom: 60px;
    font-size: 28px;
    font-weight: 500;
  }

  &__members {
    display: flex;
    flex-wrap: wrap;
  }

  &__member {
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 8px;
    margin-right: 24px;
    margin-bottom: 24px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 1264px) {
    &__members {
      justify-content: space-around;
    }

    &__member {
      margin-right: 20px;
      margin-bottom: calc(max((100% - 280px * 3) / 3, 20px));
    }
  }

  @media (max-width: 960px) {
    &__title {
      margin-bottom: 30px;
      font-size: 20px;
    }

    &__member {
      margin-bottom: calc(max((100% - 280px * 2) / 2 - 20px, 20px));
    }
  }

  @media (max-width: 600px) {
    &__title {
      margin-bottom: 20px;
      font-size: 18px;
    }

    &__member {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.outsourcer-details {
  display: grid;
  margin-top: 35px;
  font-size: 15px;
  font-weight: 400;
  grid-template-columns: 35% 15% 15% 15% 20%;
  line-height: 26px;

  &__block {
    &__header {
      background-color: #f9f9f9;
      color: #848484;

      @media (max-width: 1410px) {
        & {
          height: 79px;
        }
      }
    }

    &__body {
      color: #1c1c1c;

      &.email {
        display: block;
        width: 100%;
        padding: 16px 0 11px;
        color: #528fc9;
        text-align: center;
      }
    }

    p {
      width: 100%;
      padding: 16px 0 11px;
      text-align: center;
    }
  }

  @media (max-width: 850px) {
    & {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 1fr);

      &__block {
        display: flex;
      }
    }
  }
}

.outsourcer-member {
  text-align: center;

  &__avatar {
    overflow: hidden;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  &__photo {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__initials {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #fedd46;
    font-size: 26px;
    font-weight: 500;
  }

  &__name {
    margin-bottom: 6px;
  }

  &__role {
    margin-bottom: 16px;
    color: $grey-600;
    font-size: 13px;
  }

  &__email {
    @include ellipsis;

    max-width: 100%;
    color: $blue-500;
  }
}
</style>
