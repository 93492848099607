var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c(
        "div",
        {
          staticClass: "block__button button",
          class: { blocked: _vm.ableToCreateNewResponsible },
          on: { click: _vm.openModal },
        },
        [
          _c("div", { staticClass: "button__text" }, [
            _vm._v("Добавить представителя"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "button__icon" }, [
            _c(
              "svg",
              {
                attrs: {
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 11 10",
                  width: "11",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M6 4.5h4.5v1H6V10H5V5.5H.5v-1H5V0h1v4.5Z",
                    fill: "#6B6B6B",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "FadeTransition",
        [
          _vm.isModalOpened
            ? _c("AppModal", {
                attrs: { "max-width": 516 },
                on: { close: _vm.closeModal },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Добавление представителя")]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "ФИО*",
                              name: "add-new-agent-block-full-name",
                              placeholder: " Введите полное имя",
                              "error-message": _vm.fullNameErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.fullNameErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.fullName,
                              callback: function ($$v) {
                                _vm.fullName =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "fullName",
                            },
                          }),
                          _vm._v(" "),
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Должность*",
                              name: "add-new-agent-block-position",
                              placeholder: " Введите должность",
                              "error-message": _vm.positionErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.positionErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.position,
                              callback: function ($$v) {
                                _vm.position =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "position",
                            },
                          }),
                          _vm._v(" "),
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Номер телефона*",
                              name: "add-new-agent-block-phone",
                              placeholder: " Введите номер телефона",
                              "error-message": _vm.phoneErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.phoneErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.phone,
                              callback: function ($$v) {
                                _vm.phone =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "phone",
                            },
                          }),
                          _vm._v(" "),
                          _c("CabTextField", {
                            staticClass: "modal__input",
                            attrs: {
                              label: "Адрес электронной почты*",
                              name: "add-new-agent-block-email",
                              placeholder: " Введите адрес электронной почты",
                              "error-message": _vm.emailErrorMessage,
                            },
                            on: {
                              input: function ($event) {
                                _vm.emailErrorMessage = ""
                              },
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "email",
                            },
                          }),
                          _vm._v(" "),
                          _c("CabTextarea", {
                            attrs: {
                              name: "add-new-agent-block-comment",
                              label: "Комментарий",
                            },
                            model: {
                              value: _vm.comment,
                              callback: function ($$v) {
                                _vm.comment =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "comment",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "modal__buttons" },
                            [
                              _c("div", { staticClass: "filler" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "modal__cancel",
                                  on: { click: _vm.closeModal },
                                },
                                [_vm._v("Отмена")]
                              ),
                              _vm._v(" "),
                              _c(
                                "ButtonWithLoader",
                                {
                                  staticClass: "modal__save",
                                  attrs: { "is-loading": _vm.agentIsLoading },
                                  on: { click: _vm.addAgent },
                                },
                                [
                                  _vm._v(
                                    "\n            Добавить представителя\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  833135598
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }